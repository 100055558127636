<script setup lang="ts">
import { useI18n } from '@papershift/locale/src/i18n'

const { t } = useI18n()
</script>

<template>
  <div class="px-6 pb-6 flex bg-white">
    <div
      class="text-center w-full p-2 mt-5 text-sm text-red-800 rounded-lg bg-red-50"
    >
      {{ t('closed_chat') }}
    </div>
  </div>
</template>

/* v8 ignore start */
<i18n locale="en">
closed_chat: This chat is closed. Reopen it to send messages.
</i18n>

<i18n locale="de">
closed_chat: Dieser Chat ist geschlossen. Öffne ihn, um Nachrichten zu senden.
</i18n>
/* v8 ignore stop */
