<script setup lang="ts">
import {
  EditorIconBold,
  EditorIconCode,
  EditorIconItalic,
  EditorIconListOrdered,
  EditorIconListUnordered,
  EditorIconStrikethrough,
  ChevronDownIcon,
  EditorIconChecklist,
} from '../icons'
import type { Editor } from '@tiptap/vue-3'
import type { Option } from '../Dropdown.vue'
import Dropdown from '../Dropdown.vue'
import DropdownOption from '../DropdownOption.vue'
import { ref } from 'vue'
import i18n, { addMessages } from '@papershift/locale/src/i18n'

const { t } = i18n.global

addMessages({
  en: {
    text_style: {
      bold: 'Bold',
      italic: 'Italic',
      strikethrough: 'Strikethrough',
      code: 'Code',
    },
    list: {
      bulletlist: 'Bullet List',
      orderedlist: 'Ordered List',
      checklist: 'Checklist',
    },
  },
  de: {
    text_style: {
      bold: 'Fett',
      italic: 'Kursiv',
      strikethrough: 'Durchgestrichen',
      code: 'Code',
    },
    list: {
      bulletlist: 'Aufzählung',
      orderedlist: 'Nummerierte Liste',
      checklist: 'Checkliste',
    },
  },
})

const props = defineProps<{
  editor?: Editor
}>()

defineSlots<{
  'action-item-right': any
}>()

const textStyleOptions = [
  { value: 'bold', label: t('text_style.bold'), icon: EditorIconBold },
  { value: 'italic', label: t('text_style.italic'), icon: EditorIconItalic },
  {
    value: 'strike',
    label: t('text_style.strikethrough'),
    icon: EditorIconStrikethrough,
  },
  { value: 'code', label: t('text_style.code'), icon: EditorIconCode },
]

const listOptions = [
  {
    value: 'bulletList',
    label: t('list.bulletlist'),
    icon: EditorIconListUnordered,
  },
  {
    value: 'orderedList',
    label: t('list.orderedlist'),
    icon: EditorIconListOrdered,
  },
  {
    value: 'taskList',
    label: t('list.checklist'),
    icon: EditorIconChecklist,
  },
]

type TextStyleOption = 'bold' | 'italic' | 'strike' | 'code'
type ListOption = 'bulletList' | 'orderedList' | 'taskList'

const selectedTextStyle = ref(textStyleOptions[0])
const selectedList = ref(listOptions[0])

const handleTextStyle = (option: Option<TextStyleOption>) => {
  const chain = props.editor!.chain().focus()

  switch (option.value) {
    case 'bold':
      chain.toggleBold().run()
      break
    case 'italic':
      chain.toggleItalic().run()
      break
    case 'strike':
      chain.toggleStrike().run()
      break
    case 'code':
      chain.toggleCode().run()
      break
  }

  selectedTextStyle.value = textStyleOptions.find(
    (o) => o.value === option.value
  )!
}

const handleList = (option: Option<ListOption>) => {
  const chain = props.editor!.chain().focus()

  switch (option.value) {
    case 'bulletList':
      chain.toggleBulletList().run()
      break
    case 'orderedList':
      chain.toggleOrderedList().run()
      break
    case 'taskList':
      chain.toggleTaskList().run()
      break
  }

  selectedList.value = listOptions.find((o) => o.value === option.value)!
}
</script>

<template>
  <div class="control-group flex">
    <div v-if="editor" class="flex divide-x p-1 items-center">
      <Dropdown
        id="text-style-dropdown"
        class="toolbar-dropdown"
        :options="textStyleOptions"
      >
        <template #trigger>
          <component
            :is="selectedTextStyle.icon"
            :class="{
              'border-b-2 border-pink-500 -mb-px': editor.isActive(
                selectedTextStyle.value
              ),
            }"
          />
          <ChevronDownIcon />
        </template>
        <template #option="{ option }">
          <DropdownOption
            :active="editor.isActive(option.value)"
            :icon="option.icon"
            :label="option.label"
            :theme="option.theme"
            @click="handleTextStyle(option as Option<TextStyleOption>)"
          />
        </template>
      </Dropdown>

      <Dropdown
        id="list-dropdown"
        class="toolbar-dropdown"
        :options="listOptions"
      >
        <template #trigger>
          <component
            :is="selectedList.icon"
            :class="{
              'border-b-2 border-pink-500 -mb-px': editor.isActive(
                selectedList.value
              ),
            }"
          />
          <ChevronDownIcon />
        </template>
        <template #option="{ option }">
          <DropdownOption
            :active="editor.isActive(option.value)"
            :icon="option.icon"
            :label="option.label"
            :theme="option.theme"
            @click="handleList(option as Option<ListOption>)"
          />
        </template>
      </Dropdown>
    </div>

    <div class="ml-auto p-1">
      <slot name="action-item-right" />
    </div>
  </div>
</template>

<style scoped>
.toolbar-dropdown :deep(button) {
  @apply ring-0 bg-transparent border-none shadow-none;
}
</style>
