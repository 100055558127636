<script setup lang="ts">
import Button from '../Button.vue'
import i18n, { addMessages } from '@papershift/locale/src/i18n'

const { t } = i18n.global

addMessages({
  en: {
    cancel: 'Cancel',
    save: 'Save',
  },
  de: {
    cancel: 'Abbrechen',
    save: 'Speichern',
  },
})

defineProps<{
  id: string
}>()

defineEmits<{
  save: []
  cancel: []
}>()
</script>

<template>
  <div class="editor-footer flex items-end justify-end mt-2 p-2">
    <Button
      :id="`${id}-cancel_btn`"
      appearance="secondary"
      :tabindex="98"
      type="button"
      :label="t('cancel')"
      class="mt-3 sm:mt-0 sm:w-auto"
      @click="$emit('cancel')"
    />
    <Button
      :id="`${id}-save_btn`"
      appearance="primary"
      :tabindex="97"
      type="submit"
      :label="t('save')"
      class="sm:ml-3 sm:w-auto ml-1"
      @click="$emit('save')"
    />
  </div>
</template>
