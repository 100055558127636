<script setup lang="ts">
import { computed, ref } from 'vue'
import useAuthStore from '@/stores/auth/auth.store'
import Avatar from '@/components/Avatar.vue'
import type { ChatMessage } from '@papershift/api/src/chat'
import { Tippy } from 'vue-tippy'
import useDisplaySettingsStore from '@/stores/display-settings/display-settings.store'
import { format } from 'date-fns'
import useChatMessageActions from '@/components/composables/use-chat-message-actions'
import { EllipsisVerticalIcon } from '@papershift/ui/src/icons'
import { useI18n } from '@papershift/locale/src/i18n'
import EditorFooter from '@papershift/ui/src/editor/EditorFooter.vue'

const { t } = useI18n()

const props = defineProps<{
  message: ChatMessage
  isFollowUp: boolean
  isChatOpen: boolean
}>()

const content = ref(props.message.content)

const {
  getDropdownActions,
  handleSelectAction,
  editChatMessage,
  isEditorActive,
} = useChatMessageActions()

const authStore = useAuthStore()
const displaySettingsStore = useDisplaySettingsStore()
const language = computed(() => displaySettingsStore.settings.language)

const self = computed(() => props.message.author_id === authStore.user!.id)
const createdAt = computed(() =>
  format(new Date(props.message.created_at), 'HH:mm')
)
</script>

<template>
  <div class="chat-message xs:my-3 md:my-2.5 flex">
    <div class="flex-shrink-0" :class="{ 'ml-9': isFollowUp }">
      <Tippy placement="left-start" :content="message.author?.name">
        <Avatar v-if="!isFollowUp" :user="message.author" class="w-9 h-9" />
      </Tippy>
    </div>

    <div v-if="isEditorActive">
      <CompactRichEditor
        class="ml-4 border border-t-0"
        :content="message.content"
        :editable="true"
        :sticky-header="false"
        @enter-pressed="editChatMessage(message, content)"
        @update="content = $event"
      >
        <template #footer>
          <EditorFooter
            :id="`chat-message-${message.id}`"
            @save="editChatMessage(message, content)"
            @cancel="isEditorActive = false"
          />
        </template>
      </CompactRichEditor>
    </div>

    <div v-else class="flex items-end">
      <div
        class="group p-3 rounded-b transition duration-500 w-full rounded-tl ml-4 order-2"
        :class="{
          'bg-indigo-50': self,
          'bg-gray-50': !self,
        }"
      >
        <!-- eslint-disable vue/no-v-html -->
        <p
          class="chat-message-content prose prose-sm outline-none text-black opacity-60 text-sm font-normal leading-5 hyphens-auto break-words"
          :lang="language"
          v-html="message.content"
        />
        <span
          v-if="message.created_at !== message.updated_at"
          class="text-xs font-light opacity-60"
        >
          ({{ t('chat_message.edited') }})
        </span>

        <div class="flex justify-end w-full">
          <p
            class="outline-none text-[10px] text-black opacity-60 font-light whitespace-pre"
          >
            {{ createdAt }}
          </p>
        </div>
      </div>
    </div>

    <Dropdown
      v-if="!isEditorActive && isChatOpen"
      :id="`chat-message-${message.id}-actions`"
      :borderless="true"
      :options="getDropdownActions(message)"
      @option-select="handleSelectAction($event)"
    >
      <template #trigger>
        <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
      </template>
    </Dropdown>
  </div>
</template>

/* v8 ignore start */
<i18n locale="en">
chat_message:
  edited: edited
</i18n>

<i18n locale="de">
chat_message:
  edited: bearbeitet
</i18n>
/* v8 ignore stop */

<style scoped>
:deep(.chat-message-content) a {
  @apply text-pink-600 no-underline;
}

:deep(.chat-message-content ul[data-type='taskList'] li) {
  @apply flex gap-2;
}
:deep(.chat-message-content ul[data-type='taskList'] li label) {
  @apply p-0;
}
:deep(.chat-message-content ul[data-type='taskList'] li p) {
  @apply m-0;
}
</style>
