import api from './api'
import type {
  JsonApiListResponse,
  JsonApiResponse,
} from '@papershift/jsonapi/src/types'
import { transformQueryFilters, type FilterItem } from './filter-utils'
import type {
  Chat,
  ChatBookmark,
  ChatContextType,
  ChatMember,
  ChatMessage,
} from './chat'

export function listChats(
  page: number = 1,
  filters: FilterItem[] = []
): Promise<JsonApiListResponse<Chat>> {
  return api
    .url('/olympus/v1/chats')
    .query({
      'page[number]': page,
      'meta[permissions]': 'actions',
      include: 'latest_message,bookmark',
      sort: '-open,-updated_at',
      ...transformQueryFilters(filters),
    })
    .get()
    .json()
}

export function listChatsByContext(
  contextId: string,
  contextType: ChatContextType
): Promise<JsonApiListResponse<Chat>> {
  return api
    .url('/olympus/v1/chats')
    .query({
      include: 'bookmark',
      'meta[permissions]': 'actions,chat_membership',
      'filter[context_type]': `eq:${contextType}`,
      'filter[context_id]': `eq:${contextId}`,
    })
    .get()
    .json()
}

export function listMessagesByChat(
  chatId: string
): Promise<JsonApiListResponse<ChatMessage>> {
  return api
    .url(`/olympus/v1/chats/${chatId}/messages`)
    .query({
      include: 'author',
    })
    .get()
    .json()
}

export function createChatMessage(
  chatId: string,
  content: string
): Promise<JsonApiResponse<ChatMessage>> {
  return api
    .url(`/olympus/v1/chats/${chatId}/messages`)
    .post({
      data: {
        type: 'message',
        attributes: {
          content,
        },
      },
    })
    .json()
}

export function listChatMembers(): Promise<JsonApiListResponse<ChatMember>> {
  return listNotImplemented()
}

export function updateChatBookmark(): Promise<JsonApiResponse<ChatBookmark>> {
  return getNotImplemented()
}

export async function updateChat(): Promise<JsonApiResponse<Chat>> {
  return getNotImplemented()
}

export async function updateChatMessage(): Promise<
  JsonApiResponse<ChatMessage>
> {
  return getNotImplemented()
}

function getNotImplemented() {
  console.error('not implemented')

  return Promise.resolve({
    data: {
      id: '',
      type: '',
      attributes: {} as any,
      relationships: {},
      meta: {},
    },
  })
}

function listNotImplemented() {
  console.error('not implemented')

  return Promise.resolve({
    data: [],
    included: [],
    meta: {
      current_page: 1,
      total_pages: 1,
    },
    links: {
      first: '',
      next: null,
    },
  })
}
