<script setup lang="ts">
import { computed, nextTick, ref, toRef, watch } from 'vue'
import type { Chat, ChatMessage } from '@papershift/api/src/chat'
import useChatStore from '@/stores/chat/chat.store'
import useRoleStore from '@/stores/role/role.store'
import { useI18n } from '@papershift/locale/src/i18n'
import ChatEdit from './chat-view/ChatEdit.vue'
import useAuthStore from '@/stores/auth/auth.store'
import { createChannel } from '@/utils/cable'
import useChatBookmark from './composables/use-chat-bookmark'
import useChatEdit from './composables/use-chat-edit'
import useChat from '@/views/composables/use-chat'
import ChatMemberModal from './chat-view/ChatMemberModal.vue'
import ChatHeader from './chat-view/ChatHeader.vue'
import ChatClosedInfo from './chat-view/ChatClosedInfo.vue'
import ChatMessageEditor from './chat-view/ChatMessageEditor.vue'
import ChatMessageList from './chat-view/ChatMessageList.vue'

type BroadcastedData = {
  action: string
  message: ChatMessage
}

const authStore = useAuthStore()
const chatStore = useChatStore()
const roleStore = useRoleStore()

const messageHtmlContent = ref('')
const allMessagesRead = ref(true)
const unreadLineIsVisible = ref(false)
const chat = computed<Chat>(() => chatStore.currentChat!)

const { t } = useI18n()
const { isEmptyMessage } = useChat()
const { isEditingChat, setEditingChat, refetchChat } = useChatEdit(chat)
const { deferBookmarkUpdate, triggerBookmarkUpdate, abortBookmarkUpdate } =
  useChatBookmark()

let channel: any = null

function subscribeChannel() {
  channel = createChannel(
    {
      channel: 'ChatChannel',
      chat_id: chat.value.id,
      user_id: authStore.user!.id,
    },
    { received: handleMessage }
  )
}

function closeChat() {
  channel.unsubscribe()
  abortBookmarkUpdate()
  chatStore.closeCurrentChat()
  allMessagesRead.value = true
}

async function handleMessage(data: BroadcastedData) {
  const newMessage = data.message
  chatStore.prependMessage(chat.value.id, newMessage)

  await nextTick()

  deferBookmarkUpdate()

  // if the message is from current user, immediately mark chat as read
  // TODO: websocket interception does not work in tests, see onboarding-overview-actions.spec.ts
  /* v8 ignore start */
  if (newMessage.author?.id === authStore.user!.id) {
    triggerBookmarkUpdate()
    allMessagesRead.value = true
  }
  /* v8 ignore stop */
}

async function submit() {
  if (isEmptyMessage(messageHtmlContent.value)) return

  await chatStore.createChatMessage(
    chat.value.id,
    messageHtmlContent.value.trim()
  )
  messageHtmlContent.value = ''
}

async function fetchChatMembers() {
  await roleStore.fetchRoles()
  return chatStore.fetchChatMembersWithRoles(chat.value.id)
}

watch(
  () => chatStore.currentChat?.id,
  async (currentChatId) => {
    if (currentChatId) {
      channel?.unsubscribe()
      subscribeChannel()
      fetchChatMembers()
    }
  }
)

watch(toRef(chatStore, 'currentChatMessages'), (messages) => {
  if (allMessagesRead.value && messages.some((message) => message.isUnread)) {
    allMessagesRead.value = false
  }
})
</script>

<template>
  <teleport to="body">
    <div
      class="pointer-events-none z-10 fixed inset-y-0 right-auto sm:right-0 flex max-w-full sm:pl-10"
    >
      <Transition
        appear
        enter-active-class="transform transition ease-in-out duration-800 sm:duration-500"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-800 sm:duration-500"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <div
          v-if="chatStore.currentChat !== null"
          id="chat-view"
          class="chat pointer-events-auto w-screen max-w-full sm:max-w-md"
        >
          <div class="flex h-full flex-col bg-white pt-6 shadow-xl">
            <ChatHeader
              :chat="chat"
              @edit-triggered="setEditingChat(true)"
              @close-triggered="closeChat()"
            />

            <ChatMemberModal class="mt-3 sm:mt-0" :chat="chat" />

            <ChatMessageList
              class="mt-6 grow"
              :chat="chat"
              @fetched-initial="deferBookmarkUpdate()"
              @unread-line-visibility-changed="unreadLineIsVisible = $event"
            />

            <div
              v-if="!(allMessagesRead || unreadLineIsVisible)"
              class="w-full text-center text-sm text-pink-400 py-3"
            >
              {{ t('scroll_more') }}
            </div>

            <ChatMessageEditor
              v-if="chat.open"
              v-model:message-content="messageHtmlContent"
              class="sticky bottom-0 w-full h-auto"
              @submit="submit"
            />
            <ChatClosedInfo v-else class="sticky bottom-0 border-t" />
          </div>
        </div>
      </Transition>
    </div>
  </teleport>

  <ChatEdit
    :chat-data="isEditingChat ? chat : null"
    @close="setEditingChat(false)"
    @updated="refetchChat()"
  />
</template>

/* v8 ignore start */
<i18n locale="en">
scroll_more: Scroll up to see unread messages
</i18n>

<i18n locale="de">
scroll_more: Nach oben scrollen, um ungelesene Nachrichten zu sehen
</i18n>
/* v8 ignore stop */
