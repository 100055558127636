import type { ChatMessage } from '@papershift/api/src/chat'
import { type Option } from '@papershift/ui/src/Dropdown.vue'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import {
  FaceSmileIcon,
  PencilSquareOutlineIcon,
} from '@papershift/ui/src/icons'
import useAuthStore from '@/stores/auth/auth.store'
import useChatStore from '@/stores/chat/chat.store'
import { ref } from 'vue'

export default function useChatMessageActions() {
  const { t } = i18n.global
  const isEditorActive = ref(false)

  const getDropdownActions = (row: ChatMessage) => {
    const authStore = useAuthStore()
    const actions: Option[] = []

    actions.push({
      label: t('chat_message.actions.react'),
      value: 'react',
      icon: FaceSmileIcon,
    })

    if (row.author_id === authStore.user?.id) {
      actions.push({
        label: t('chat_message.actions.edit'),
        value: 'edit',
        icon: PencilSquareOutlineIcon,
      })
    }

    return actions
  }

  async function toggleEditor(open: boolean) {
    isEditorActive.value = open
  }

  function handleSelectAction(action: Option) {
    if (action.value === 'edit') {
      toggleEditor(true)
    }
  }

  async function editChatMessage(row: ChatMessage, content: string) {
    const chatStore = useChatStore()

    await chatStore.updateChatMessage(row.chat_id, row.id, content)
    await chatStore.fetchMessagesForCurrentChat(true) // todo: remove this when realtime is implemented

    toggleEditor(false)
  }

  return {
    getDropdownActions,
    toggleEditor,
    handleSelectAction,
    editChatMessage,

    isEditorActive,
  }
}

addMessages({
  en: {
    chat_message: {
      actions: {
        react: 'React',
        edit: 'Edit',
      },
    },
  },
  de: {
    chat_message: {
      actions: {
        react: 'Reagieren',
        edit: 'Bearbeiten',
      },
    },
  },
})
