<script setup lang="ts">
import { computed, nextTick, ref, watch, type Ref } from 'vue'
import {
  useElementVisibility,
  useInfiniteScroll,
  useScroll,
} from '@vueuse/core'
import { useI18n } from '@papershift/locale/src/i18n'
import { useLocalizedDate } from '../composables/use-localized-date'
import useChatStore from '@/stores/chat/chat.store'
import useElementScrolling from './composables/use-element-scrolling'
import type { Chat, ChatMessage } from '@papershift/api/src/chat'
import { ArrowLongDownIcon } from '@papershift/ui/src/icons'
import ChatMessageComponent from '@/components/ChatMessage.vue'

type Message = ChatMessage & { isUnread: boolean; isFollowUp: boolean }

const MIN_TOP_DISTANCE = 128 // was the height of two single-line messages

const props = defineProps<{
  chat: Chat
}>()

const emit = defineEmits<{
  'fetched-initial': []
  'unread-line-visibility-changed': [isVisible: boolean]
}>()

const { t } = useI18n()
const { formatDate } = useLocalizedDate()
const chatStore = useChatStore()

const messagesContainerRef = ref<HTMLDivElement | null>(null)
const firstPagesLoaded = ref(false)
const unreadLinesRef = ref(null)

const unreadLineIsVisible: Ref<boolean> = useElementVisibility(unreadLinesRef)
const { scrollLastChildIntoView } = useElementScrolling(messagesContainerRef)

const groupedMessages = computed(() => {
  const groups: Record<string, Message[]> = {}

  chatStore.currentChatMessages.forEach((message, index) => {
    const isFollowUp =
      message.author_id === chatStore.currentChatMessages[index - 1]?.author_id
    const date = formatDate(message.created_at)!
    if (!groups[date]) groups[date] = []
    groups[date].push({ ...message, isFollowUp })
  })

  return groups
})

async function loadInitialMessages() {
  firstPagesLoaded.value = false

  await chatStore.fetchMessagesForCurrentChat(true)
  await nextTick()

  while (
    chatStore.currentChatCanLoadMoreMessages() &&
    // not enough items yet for useInfiniteScroll to take over
    messagesContainerRef.value!.scrollHeight <=
      messagesContainerRef.value!.clientHeight + MIN_TOP_DISTANCE
  ) {
    await chatStore.fetchMessagesForCurrentChat(false)
    await nextTick()
  }

  // do the initial scroll to bottom
  scrollLastChildIntoView()

  emit('fetched-initial')

  firstPagesLoaded.value = true
}

async function loadMoreMessages() {
  const preLoadHeight = messagesContainerRef.value!.scrollHeight

  await chatStore.fetchMessagesForCurrentChat(false)
  await nextTick()

  // restore the "scrolled distance from the bottom",
  // but only if the container has been scrolled all the way to the top -
  // if it hasn't been scrolled to the top fully, this happens automagically
  //
  // useScroll is used to do this check,
  // because useScroll is what useInfiniteScroll uses under the hood
  if (useScroll(messagesContainerRef).arrivedState.top) {
    const postLoadHeight = messagesContainerRef.value!.scrollHeight
    messagesContainerRef.value!.scrollBy({
      top: postLoadHeight - preLoadHeight,
      behavior: 'instant',
    })
  }
}

watch(() => props.chat.id, loadInitialMessages, { immediate: true })

watch(unreadLineIsVisible, (isVisible) => {
  emit('unread-line-visibility-changed', isVisible)
})

// scroll to bottom when new message arrives
watch(
  () => {
    const messages = chatStore.currentChatMessages
    const mostRecentMessage = messages[messages.length - 1]

    return mostRecentMessage?.id
  },
  async () => {
    await nextTick()
    scrollLastChildIntoView()
  }
)

useInfiniteScroll(messagesContainerRef, loadMoreMessages, {
  direction: 'top',
  distance: MIN_TOP_DISTANCE,
  canLoadMore: () =>
    firstPagesLoaded.value && chatStore.currentChatCanLoadMoreMessages(),
})
</script>

<template>
  <div
    ref="messagesContainerRef"
    class="chat-messages relative flex-1 px-4 sm:px-6 overflow-y-scroll flex flex-col"
  >
    <div v-for="(messages, date) in groupedMessages" :key="date">
      <div class="sticky top-4 text-center text-xs text-gray-500 my-4 z-10">
        <span class="border border-gray-400 bg-white rounded-lg p-2">
          {{ date }}
        </span>
      </div>
      <div class="w-full border-t border-gray-300 -mt-6 mb-8"></div>

      <div v-for="message in messages" :key="message.id">
        <div v-if="message.isUnread" ref="unreadLinesRef" class="w-full">
          <div class="text-pink-400 text-sm flex flex-wrap justify-between">
            <div>{{ t('info.new') }}</div>
            <ArrowLongDownIcon class="w-4 h-4" />
          </div>

          <div class="border-t border-pink-300 mb-4"></div>
        </div>

        <ChatMessageComponent
          :key="message.id"
          class="mb-4"
          :message="message"
          :is-follow-up="message.isFollowUp"
          :is-chat-open="chat.open"
        />
      </div>
    </div>
  </div>
</template>

/* v8 ignore start */
<i18n locale="en">
info:
  new: New
</i18n>

<i18n locale="de">
info:
  new: Neu
</i18n>
/* v8 ignore stop */
