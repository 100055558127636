<script setup lang="ts">
import { ref, toRef, watch } from 'vue'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import type { SuggestionKeyDownProps } from '@tiptap/suggestion'
import type { SuggestionItem } from '../composables/use-editor-mention-suggestions'

defineExpose({ onKeyDown })

const { t } = i18n.global

addMessages({
  en: { no_results: 'No results' },
  de: { no_results: 'Keine Ergebnisse' },
})

const props = defineProps<{
  items: SuggestionItem[]
}>()
const emit = defineEmits<{
  'select-item': [item: SuggestionItem]
}>()

const suggestions = toRef(props, 'items')

const selectedIndex = ref(0)

watch(
  () => suggestions.value,
  () => {
    selectedIndex.value = 0
  }
)

function onKeyDown({ event }: SuggestionKeyDownProps) {
  if (event.key === 'ArrowUp') {
    focusPrev()
    return true
  }

  if (event.key === 'ArrowDown') {
    focusNext()
    return true
  }

  if (event.key === 'Enter') {
    selectItem(selectedIndex.value)
    return true
  }

  return false
}

function focusPrev() {
  selectedIndex.value =
    (selectedIndex.value + suggestions.value.length - 1) %
    suggestions.value.length
}

function focusNext() {
  selectedIndex.value = (selectedIndex.value + 1) % suggestions.value.length
}

function selectItem(index: number) {
  const item = suggestions.value[index]
  emit('select-item', item)
}
</script>

<template>
  <ul v-if="suggestions.length" class="py-1 px-0">
    <li
      v-for="(item, index) in suggestions"
      :key="index"
      :class="{ 'bg-slate-600': index === selectedIndex }"
      :aria-selected="index === selectedIndex"
      role="option"
      class="w-full py-1 px-2 text-left rounded text-sm hover:bg-slate-700"
      @click="selectItem(index)"
    >
      {{ item.label }}
    </li>
  </ul>

  <div v-else class="text-xs">{{ t('no_results') }}</div>
</template>
