<script setup lang="ts">
import { EditorContent, Extension, type EditorOptions } from '@tiptap/vue-3'
import { toRef } from 'vue'
import Mention from '@tiptap/extension-mention'
import EditorCompactToolbar from './editor/EditorCompactToolbar.vue'
import EditorCompactToolbarMobile from './editor/EditorCompactToolbarMobile.vue'
import useRichEditor from './composables/use-rich-editor'
import {
  default as useEditorMentionSuggestions,
  type SuggestionItem,
} from './composables/use-editor-mention-suggestions'

const props = withDefaults(
  defineProps<{
    content?: string
    editable?: boolean
    placeholder: string
    mentionSuggestions?: SuggestionItem[]
    stickyHeader?: boolean
  }>(),
  { stickyHeader: true }
)

const emit = defineEmits<{
  update: [string]
  'enter-pressed': []
  save: []
  cancel: []
}>()

defineSlots<{
  'action-item-right': any
  footer?: any
}>()

const data = {
  content: toRef(props, 'content'),
  editable: toRef(props, 'editable'),
  placeholder: toRef(props, 'placeholder'),
  cssClass: 'min-h-2 max-h-96 overflow-auto m-5',
}
const editorOptions: Partial<EditorOptions> = {
  onUpdate({ editor }) {
    emit('update', editor.getHTML())
  },
  extensions: [
    Extension.create({
      addKeyboardShortcuts: () => ({
        Enter: () => {
          emit('enter-pressed')
          return true
        },
        'Shift-Enter': () => {
          return (
            editor.value.commands.splitListItem('listItem') ||
            editor.value.commands.splitListItem('taskItem') ||
            editor.value.commands.liftEmptyBlock() ||
            editor.value.commands.splitBlock()
          )
        },
      }),
    }),
  ],
}

if (props.mentionSuggestions) {
  editorOptions.extensions!.push(
    Mention.configure({
      HTMLAttributes: {
        class: 'mention',
      },
      suggestion: useEditorMentionSuggestions(
        toRef(props, 'mentionSuggestions')
      ),
    })
  )
}

const { editor } = useRichEditor(data, editorOptions)
</script>

<template>
  <div v-if="editor">
    <template v-if="editable">
      <EditorCompactToolbar
        class="hidden sm:flex border-t border-slate-300 bg-white desktop-toolbar"
        :class="stickyHeader ? 'sticky top-20 z-10' : ''"
        :editor="editor"
      >
        <template #action-item-right>
          <slot name="action-item-right" />
        </template>
      </EditorCompactToolbar>

      <EditorCompactToolbarMobile
        class="border-t border-slate-300 bg-white mobile-toolbar sm:hidden"
        :class="stickyHeader ? 'sticky top-20 z-10' : ''"
        :editor="editor"
      >
        <template #action-item-right>
          <slot name="action-item-right" />
        </template>
      </EditorCompactToolbarMobile>
    </template>

    <EditorContent class="bg-white" :editor="editor" />

    <slot name="footer" />
  </div>
</template>

<style scoped src="./editor/rich-editor.css"></style>
