<script setup lang="ts">
import ToolbarButton from './EditorToolbarButton.vue'
import {
  EditorIconBold,
  EditorIconCode,
  EditorIconItalic,
  EditorIconListOrdered,
  EditorIconListUnordered,
  EditorIconStrikethrough,
  EditorIconChecklist,
} from '../icons'
import type { Editor } from '@tiptap/vue-3'
import { Tippy } from 'vue-tippy'
import i18n, { addMessages } from '@papershift/locale/src/i18n'

const { t } = i18n.global

defineProps<{
  editor?: Editor
}>()

defineSlots<{
  'action-item-right': any
}>()

addMessages({
  en: {
    button: {
      formatting: {
        bold: 'Bold',
        italic: 'Italic',
        strikethrough: 'Strikethrough',
        code: 'Code',
      },

      other: {
        bulletlist: 'Bullet List',
        orderedlist: 'Ordered List',
        checklist: 'Checklist',
      },
    },
  },
  de: {
    button: {
      formatting: {
        bold: 'Fett',
        italic: 'Kursiv',
        strikethrough: 'Durchgestrichen',
        code: 'Code',
      },
      other: {
        bulletlist: 'Aufzählung',
        orderedlist: 'Nummerierte Liste',
        codeblock: 'Code-Block',
        checklist: 'Checkliste',
        quote: 'Zitat',
        link: 'Link',
      },
    },
  },
})
</script>

<template>
  <div class="control-group flex">
    <div v-if="editor" class="flex divide-x px-3 py-1 items-center">
      <div class="flex gap-4 pr-4">
        <Tippy :content="t('button.formatting.bold')" placement="top">
          <ToolbarButton
            :disabled="!editor.can().chain().focus().toggleBold().run()"
            :active="editor.isActive('bold')"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <EditorIconBold />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.formatting.italic')" placement="top">
          <ToolbarButton
            :disabled="!editor.can().chain().focus().toggleItalic().run()"
            :active="editor.isActive('italic')"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <EditorIconItalic />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.formatting.strikethrough')" placement="top">
          <ToolbarButton
            :disabled="!editor.can().chain().focus().toggleStrike().run()"
            :active="editor.isActive('strike')"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <EditorIconStrikethrough />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.formatting.code')" placement="top">
          <ToolbarButton
            :disabled="!editor.can().chain().focus().toggleCode().run()"
            :active="editor.isActive('code')"
            @click="editor.chain().focus().toggleCode().run()"
          >
            <EditorIconCode />
          </ToolbarButton>
        </Tippy>
      </div>

      <div class="flex gap-4 pl-4">
        <Tippy :content="t('button.other.bulletlist')" placement="top">
          <ToolbarButton
            :active="editor.isActive('bulletList')"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            <EditorIconListUnordered />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.other.orderedlist')" placement="top">
          <ToolbarButton
            :active="editor.isActive('orderedList')"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            <EditorIconListOrdered />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.other.checklist')" placement="top">
          <ToolbarButton
            :active="editor.isActive('taskList')"
            @click="editor.chain().focus().toggleTaskList().run()"
          >
            <EditorIconChecklist />
          </ToolbarButton>
        </Tippy>
      </div>
    </div>

    <div class="ml-auto p-1">
      <slot name="action-item-right" />
    </div>
  </div>
</template>
