import wretch from 'wretch'
import type { SetRequired } from 'type-fest'
import api from './api'
import type { ViewCreatePermission, ViewEditPermission } from './types'
import type { Team } from './team'
import type { AccountMembership, TeamMembership } from './role'
import { type FilterItem, transformQueryFilters } from './filter-utils'
import type {
  JsonApiListResponse,
  JsonApiResponse,
} from '@papershift/jsonapi/src/types'

type Status = 'created' | 'invited' | 'active'

export type UserMeta = {
  permissions?: {
    actions?: {
      edit: boolean
      delete: boolean
      invite: {
        create: boolean
        delete: boolean
      }
    }
  }
}
export type User = {
  id: string
  name: string
  avatar?: string | null
  email?: string
  status: Status
  teams?: Team[]
  account_memberships?: AccountMembership[]
  team_memberships?: TeamMembership[]
} & UserMeta

export type ProfilePermissions = {
  onboarding: { view: boolean }
  personnel_file_documents: { view: boolean }
  personnel_file_general: ViewEditPermission
  personnel_file_master_data: ViewEditPermission
  teams: ViewEditPermission
  settings: { view: boolean }
  contracts: ViewCreatePermission
}
export type ProfileUser = User & {
  profilePermissions: ProfilePermissions
}
export type UpdateUserParams = SetRequired<Partial<User>, 'id'>

type UploadParams = {
  url: string
  file: Blob
  mime: string
}

export function createUser(
  params: Partial<User>
): Promise<JsonApiResponse<User>> {
  return api
    .url(`/api/v1/users`)
    .post({
      data: {
        type: 'user',
        attributes: {
          name: params.name,
          email: params.email,
        },
      },
    })
    .json()
}

export function updateUser(params: UpdateUserParams) {
  return api
    .url(`/api/v1/users/${params.id}`)
    .patch({
      data: {
        type: 'user',
        attributes: {
          name: params.name,
          email: params.email,
          avatar: params.avatar,
        },
      },
    })
    .json()
}

export function listUsers(
  filters: FilterItem[] = [],
  page: number = 1
): Promise<JsonApiListResponse<User>> {
  const query = transformQueryFilters(filters)

  return api
    .url(`/api/v1/users`)
    .query({
      sort: 'name',
      'meta[permissions]': 'actions',
      'page[number]': page,
      ...query,
    })
    .get()
    .json()
}

export function listUsersWithAccountRoles(
  filters: FilterItem[] = [],
  page: number = 1
): Promise<JsonApiListResponse<User>> {
  const query = transformQueryFilters(filters)
  return api
    .url('/api/v1/users')
    .query({
      include: 'account_memberships',
      sort: 'name',
      'page[number]': page,
      ...query,
    })
    .get()
    .json()
}

export function listUsersByTeam(
  teamId: string
): Promise<JsonApiListResponse<User>> {
  return api
    .url(
      `/api/v1/users?sort=name&filter[teams_id]=eq:${teamId}&include=team_memberships&filter[team_memberships][team_id]=eq:${teamId}`
    )
    .get()
    .json()
}

export function listAvailableUsers(
  filters: FilterItem[]
): Promise<JsonApiListResponse<User>> {
  const query = transformQueryFilters(filters)

  return api
    .url(`/api/v1/users`)
    .query({
      sort: 'name',
      ...query,
    })
    .get()
    .json()
}

export function getUser(id: string): Promise<JsonApiResponse<User>> {
  return api.url(`/api/v1/users/${id}?meta[permissions]=actions`).get().json()
}

export function getUserWithRolesInAccount(
  userId: string
): Promise<JsonApiResponse<User>> {
  return api
    .url(`/api/v1/users/${userId}?include=account_memberships`)
    .get()
    .json()
}

export function getUserWithRolesInTeam(
  userId: string,
  teamId: string
): Promise<JsonApiResponse<User>> {
  return api
    .url(
      `/api/v1/users/${userId}?include=team_memberships&filter[team_memberships][team_id]=eq:${teamId}`
    )
    .get()
    .json()
}

export function deleteUser(id: string) {
  return api.url(`/api/v1/users/${id}`).delete().json()
}

export function createInvite(userId: string): Promise<JsonApiResponse<User>> {
  return api
    .url(`/api/v1/users/${userId}/invite?meta[permissions]=actions`)
    .post({})
    .json()
}

export function deleteInvite(userId: string): Promise<JsonApiResponse<User>> {
  return api
    .url(`/api/v1/users/${userId}/invite?meta[permissions]=actions`)
    .delete()
    .json()
}

export function listProfilePermissions(
  userId: string
): Promise<JsonApiResponse<ProfilePermissions>> {
  return api.url(`/ui/v1/profile-permissions?user_id=${userId}`).get().json()
}

export function getUploadUrl(
  userId: string,
  filename: string
): Promise<JsonApiResponse<{ upload_url: string }>> {
  return api
    .url(`/api/v1/users/${userId}/generate_upload_url?filename=${filename}`)
    .get()
    .json()
}

export async function uploadFile({
  url,
  file,
  mime,
}: UploadParams): Promise<void> {
  return wretch(url).headers({ 'Content-Type': mime }).put(file).res()
}
