<script setup lang="ts">
import { ref, toRef } from 'vue'
import { useI18n } from '@papershift/locale/src/i18n'
import useChatStore from '@/stores/chat/chat.store'
import useModalToggler from '@papershift/ui/src/utils/use-modal-toggler'
import ChatMemberEdit from './ChatMemberEdit.vue'
import ChatMemberAdd from './ChatMemberAdd.vue'
import ChatMemberList from './ChatMemberList.vue'
import type { Chat } from '@papershift/api/src/chat'

const props = defineProps<{
  chat: Chat
}>()

const { t } = useI18n()
const { isModalActive, toggleModal } = useModalToggler()

const chatStore = useChatStore()

const chatMembers = toRef(chatStore, 'chatMembers')
const editingUserId = ref('')

function fetchChatMembers() {
  chatStore.fetchChatMembersWithRoles(props.chat.id)
}

function openModal() {
  toggleModal(true)
  fetchChatMembers()
}
</script>

<template>
  <div class="px-4 sm:px-6">
    <button
      class="text-sm text-pink-600 hover:text-pink-900 self-start"
      @click="openModal()"
    >
      {{ t('button') }}
    </button>

    <Modal
      :is-active="isModalActive"
      :title="t('title')"
      @close="toggleModal(false)"
    >
      <ChatMemberEdit
        v-if="chat.permissions?.chat_membership?.manage"
        :chat-id="chat.id"
        :user-id="editingUserId"
        :can-delete="chat.permissions?.chat_membership?.manage ?? false"
        @close="editingUserId = ''"
        @updated="fetchChatMembers()"
        @removed="fetchChatMembers()"
      />

      <div class="mt-5">
        <ChatMemberAdd
          v-if="chat.permissions?.chat_membership?.manage"
          :chat-id="chat.id"
          @added="fetchChatMembers()"
        />
      </div>

      <div>
        <ChatMemberList
          :members="chatMembers"
          :can-edit="chat.permissions?.chat_membership?.manage ?? false"
          @cancel-click="toggleModal(false)"
          @edit-click="editingUserId = $event"
        />
      </div>
    </Modal>
  </div>
</template>

/* v8 ignore start */
<i18n locale="en">
button: Participants
title: Manage Participants
</i18n>

<i18n locale="de">
button: Teilnehmer
title: Teilnehmer
</i18n>
/* v8 ignore stop */
