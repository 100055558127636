<script setup lang="ts">
import type { User } from '@papershift/api/src/user'
import { UserIcon } from '@papershift/ui/src/icons'
import useAvatar from './composables/use-avatar'
import { toRef } from 'vue'

const props = defineProps<{
  user?: User | null
}>()

const { avatarUrl, initials } = useAvatar(toRef(props, 'user'))
</script>

<template>
  <img v-if="avatarUrl" :src="avatarUrl" alt="" class="rounded-full" />
  <div
    v-else
    class="rounded-full bg-gray-200 text-gray-500 flex items-center justify-center"
  >
    <p v-if="initials" class="text-sm">{{ initials }}</p>
    <UserIcon v-else class="text-xl" />
  </div>
</template>
