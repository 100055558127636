import { computed, type Ref } from 'vue'
import type { User } from '@papershift/api/src/user'

export default function useAvatar(user: Ref<User | null | undefined>) {
  const avatarUrl = computed(() => {
    if (!user.value?.avatar) return null

    return `${import.meta.env.VITE_S3_URL}/${user.value.id}/${user.value.avatar}`
  })

  const initials = computed(() => {
    if (!user.value || user.value.name.length === 0) return null

    return user.value.name
      .split(' ')
      .slice(0, 2)
      .map((namePart) => namePart[0])
      .join('')
  })

  return { avatarUrl, initials }
}
