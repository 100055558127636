import { VueRenderer } from '@tiptap/vue-3'
import type { SuggestionOptions } from '@tiptap/suggestion'
import { tippy, type TippyInstance } from 'vue-tippy'
import MentionList from './../editor/MentionList.vue'
import type { Ref } from 'vue'

// keeping this structure consistent with others,
// such as Dropdown, Select, etc.
export type SuggestionItem = {
  value: string
  label: string
}

export default function useEditorMentionSuggestions(
  data: Ref<SuggestionItem[] | undefined>
): Omit<SuggestionOptions<SuggestionItem>, 'editor'> {
  return {
    items: ({ query }) => {
      if (!data.value) return []

      return data.value
        .filter((item: SuggestionItem) =>
          item.label.toLowerCase().startsWith(query.toLowerCase())
        )
        .slice(0, 5)
    },

    render: () => {
      let component: VueRenderer
      let popup: Extract<TippyInstance, { id: number }>

      return {
        onStart: (props) => {
          component = new VueRenderer(MentionList, {
            props: {
              ...props,
              onSelectItem: (item: SuggestionItem) =>
                props.command({ ...item, id: item.value }),
            },
            editor: props.editor,
          })

          if (!props.clientRect || !component.element) {
            return
          }

          popup = tippy(document.body, {
            getReferenceClientRect: props.clientRect as () => DOMRect,
            appendTo: document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },

        onUpdate(props) {
          component.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup.setProps({
            getReferenceClientRect: props.clientRect as () => DOMRect,
          })
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup.hide()

            return true
          }

          return component.ref?.onKeyDown(props)
        },

        onExit() {
          popup.destroy()
          component.destroy()
        },
      }
    },
  }
}
